@import '@lumapps/lumx/scss/variables.scss';

.widget-header {
    $this: &;
    flex-shrink: 0;

    &__heading {
        display: flex;
    }

    &__wrapper {
        @include lumx-typography('subtitle2');
        /** Override the default font-weight from subtitle2 */
        font-weight: var(--lumx-typography-font-weight-medium);
        display: flex;
        overflow: hidden;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: $lumx-spacing-unit * 1.5 0;
        margin: 0 $lumx-spacing-unit-huge;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: lumx-color-variant('dark', 'L5');
        line-height: inherit;

        .cell--is-plain &,
        .widget--has-ungrouped-container-block & {
            margin: 0;
        }

        .widget--has-ungrouped-container-block & {
            border-bottom-width: 0;
        }

        &--clickable {
            background: none;
            cursor: pointer;
            outline: none;
            color: lumx-color-variant('dark', 'N'); // Enforcing default color to avoid relying on browser default settings

            &[data-focus-visible-added] {
                outline: auto;
            }
        }

        &[aria-expanded="false"] {
            border-color: transparent;
        }

        &--layout-left #{$this}__icon {
            margin-right: $lumx-spacing-unit;
        }

        &--layout-right #{$this}__icon {
            margin-left: $lumx-spacing-unit;
        }
    }

    &__title {
        font-family:var(--lumx-widget-header-font-family);
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
    }

    &__icon {
        flex-shrink: 0;
        color: lumx-color-variant('dark', 'N'); // Enforcing default color to avoid relying on browser default settings
    }

}
