@import '@lumapps/lumx/scss/variables.scss';

.user-list {
    &__skeleton .lumx-flex-box {
        height: calc(2 * var(--lumx-typography-custom-body-line-height));
    }

    &__search-field .lumx-search-field__text-field {
        width: 100%;
    }

    &__no-results p {
        text-align: center;
    }
}

