@import '@lumapps/lumx/scss/variables.scss';

.layout {
    display: flex;
    flex-direction: column;

    &--is-loading {
        height: 100vh;
    }

    // Adapt margin on mobile
    @include mq($until: mobile) {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
