@import '@lumapps/lumx/scss/variables.scss';

$row-padding : ($lumx-spacing-unit * 1.5);

.row {
    position: relative;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-style: solid;
    border-width: 0;
}

.row-wrapper {
    position: relative;
    border-width: 0;
    border-style: solid;
    border: 0 solid lumx-color-variant('dark', 'L5');

    @include mq($from: desktop) {
        display: flex;
        margin-left: -$row-padding;
        margin-right: -$row-padding;
        flex-direction: row;

        &--is-full-width {
            padding: 0 $row-padding;
        }
    }

    &--is-full-width {
        margin: 0;
    }
}

/** Row wrapper style at the root of the layout. */
.layout > .row  {
    > .row-wrapper--is-small {
        @include mq($from: desktop) {
            width: 744px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    > .row-wrapper--is-compact {
        margin: 0 $lumx-spacing-unit-huge;
        @include wrapper('compact');

        @include mq($from: desktop) {
            width: 936px + $lumx-spacing-unit-huge;
        }
    }

    > .row-wrapper--is-regular {
        @include mq($from: desktop) {
            width: 960px + $lumx-spacing-unit-huge;
            margin-right: auto;
            margin-left: auto;
        }

        @include mq($from: wide) {
            width: 1128px + $lumx-spacing-unit-huge;
        }
    }

    > .row-wrapper:not(.row-wrapper--is-full-width):not(.row-wrapper--is-compact) {
        @include mq($until: desktop) {
            margin: 0 $lumx-spacing-unit-huge;
        }
    }
}
