@import '@lumapps/lumx/scss/variables.scss';

.widget {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink:0;
    /* Previously on .component-widget wrapper */
    margin: $lumx-spacing-unit * 1.5 0;
    border: 0 solid lumx-color-variant('dark', 'L5');
    background-color: lumx-color-variant('light', 'N');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $lumx-border-radius;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    overflow: hidden;

    @include fade-in-animate;

    &--has-ungrouped-container-block {
        background-color: transparent !important;
        box-shadow: none;
        overflow: initial;
    }

    &--is-full-height {
        flex: 1 0 auto;
    }
}
