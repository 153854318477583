@import '@lumapps/lumx/scss/variables.scss';

.widget-footer {
    $this: &;
    flex-shrink: 0;

    &__wrapper {
        display: flex;
        overflow: hidden;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: $lumx-spacing-unit * 1.5 0;
        margin: 0 $lumx-spacing-unit * 3;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: lumx-color-variant('dark', 'L5');
        color: lumx-color-variant('dark', 'L1');
        outline: none;
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        .cell--is-plain &,
        .widget--has-ungrouped-container-block & {
            margin: 0;
        }

        .widget--has-ungrouped-container-block & {
            border-top-width: 0;
        }

        &[data-focus-visible-added] {
            outline: auto;
        }

        &--layout-left {
            #{$this}__icon {
                margin-right: $lumx-spacing-unit;
            }
        }

        &--layout-right {
            #{$this}__icon {
                margin-left: $lumx-spacing-unit;
            }
        }
    }

    &__label {
        @include lumx-typography('subtitle1');
        font-family:var(--lumx-widget-footer-font-family);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__icon {
        flex-shrink: 0;
    }

}
