@import '@lumapps/lumx/scss/variables.scss';

.community-visibility {
    display: flex;
    align-items: center;

    &__type {
        @include lumx-typography('body1');
        margin-left: $lumx-spacing-unit-tiny;
    }
}
