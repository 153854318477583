@import '../../../scss/variables.scss';

.generic-entity-block {
    $self: &;
    align-items: center;

    &__detail {
        min-width: 0;
    }

    &__title, &__description {
        overflow: hidden;
        text-overflow: ellipsis;

        #{$self}--no-wrap & {
            white-space: nowrap;

        }
    }

    &--disabled {
        opacity: .5;
    }

    &__title {
        @include lumx-typography(subtitle2);
    }

    &__description, &__additional-info, &__before-additional-info {
        @include lumx-typography(body1);
        color: lumx-color-variant('dark', 'L2');

        #{$self}--theme-dark & {
            color: lumx-color-variant('light', 'L2');
        }

        /** Reset text color when inside a selected list item. */
        [aria-selected=true] &,[aria-checked=true] &, .lumx-list-item__link--is-selected & {
            color: inherit;
        }
    }
}
