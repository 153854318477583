@import '../../../../scss/variables.scss';

.navigation-skeleton {
  @include lumx-list;
  display: flex;
  flex-direction: column;
  padding: 0;

  &--horizontal {
    flex-direction: row;
    width: 100%;

    .navigation-skeleton__item:not(:last-child) {
      margin-right: $lumx-spacing-unit;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: map-get($lumx-sizes, 'm');
    padding: 0 $lumx-spacing-unit;

    &--fill {
        flex: 1 0 auto;
        min-width: map-get($lumx-sizes, 'xl');
        max-width: 236px;
    }
  }

  &__circle {
    margin-right: $lumx-spacing-unit;
    flex: 0 0 auto;
  }

  &__typography {
    flex: 1 0 auto;
  }
}
