@use "sass:math";

@import '@lumapps/lumx/scss/variables.scss';

.cell {
    $this: &;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @for $i from 1 through 12 {
        @include mq($from: desktop) {
            $column-width: #{math.div(100, 12) * $i + '%'};

            &--w-#{$i} {
                max-width: $column-width;
                flex: 0 0 $column-width;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        border-width: 0;
        border-style: solid;
        border-color: lumx-color-variant('dark', 'L5');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;

        @include mq($from: desktop) {
            padding: 0 $lumx-spacing-unit * 1.5;
        }

        #{$this}--is-plain > & {
            flex: initial;
            padding: $lumx-spacing-unit * 1.5 $lumx-spacing-unit * 3;
            margin: $lumx-spacing-unit * 1.5 0;
            background-color: $lumx-color-light-N;
            border-radius: 2px;
            box-shadow: 0 2px 4px $lumx-color-dark-L5;

            // Disable some widget style
            .widget {
                background: none;
                background-repeat: inherit;
                background-position: inherit;
                background-size: inherit;

                border-radius: 0;
                box-shadow: none;
            }

            @include mq($from: desktop) {
                padding: $lumx-spacing-unit * 3 $lumx-spacing-unit * 6;
                margin: $lumx-spacing-unit * 1.5;
            }
        }
    }
}
